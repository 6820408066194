import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/pFigjW_76pc">
    <SEO title="Heroes - Hebrews" />
  </Layout>
)

export default SermonPost
